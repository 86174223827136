import React, { Component } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import bbox from "@turf/bbox";

export default class Map extends Component {
  constructor(props) {
    super(props);
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
    this.state = {
      lng: -75.7,
      lat: 41.5,
      zoom: 5.25,
      style: {},
      mapBounds: {},
      ecosystemBounds: [],
      mapLayers: [],
      ecosystemLayers: [],
      subEcosystemLayers: [],
      detailLayers: [],
      detailFeatures: [],
      storedGeoJSONFiles: {},
      finishedDownloadingJson: false,
      layersDrawn: false,
      hasZoomedIn: false,
      detailHover: false,
      prevActiveEcosystem: null,
      prevActiveSubEcosystem: null,
    };
    this.mapContainer = React.createRef();
  }

  componentDidMount() {
    const { lng, lat, zoom } = this.state;

    this.map = new mapboxgl.Map({
      container: this.mapContainer.current,
      style: process.env.REACT_APP_MAPBOX_MAP + "?optimize=true",
      projection: "mercator",
      center: [lng, lat],
      zoom: zoom,
    });

    this.map.addControl(new mapboxgl.AttributionControl(), "top-left");
    this.map.addControl(new mapboxgl.NavigationControl(), "top-left");
    this.map.addControl(new mapboxgl.FullscreenControl(), "top-left");

    this.map.on("load", () => {
      // Store bbox geojson layers in an object prop
      // We will use these for map fitBounds and click + hover events
      const geojsonEcosystemLayers = this.props.ecosystemsForMap;
      const ecosystemSource = "RON-ecosystems-bbox";

      const geojsonDetailLayers = ["municipalities", "counties", "towns"];

      // Watershed layers will be added to the map as a subEcosystem
      const geojsonSubEcosystemLayers = this.props.subEcosystemsForMap;

      // Define geojson layers for subEcosystems, and create array with clean names for filters
      let subEcosystemNames = {};
      for (let key in this.props.subEcosystems) {
        if (this.props.subEcosystems.hasOwnProperty(key)) {
          const sanitizedKey = key
            .replace(/( River| Bay) Ecosystem/gi, "")
            .replace(/[ .]/g, "")
            .toLowerCase();
          subEcosystemNames[sanitizedKey] = this.props.subEcosystems[key];
        }
      }

      // load and store all geojson files into geojsonContent object
      let geojsonContent = this.props.geoJSONData;
      const ronEcosystems = geojsonContent["RON-ecosystems-bbox"];

      // Filter the features based on the NAME property
      const filteredFeatures = ronEcosystems.features.filter((feature) => {
        // Remove non-alphanumeric characters and convert to lowercase
        const sanitizedFeatureName = feature.properties.NAME.replace(
          /\W/g,
          ""
        ).toLowerCase();
        return geojsonEcosystemLayers.includes(sanitizedFeatureName);
      });

      // Create a new GeoJSON object with the filtered features
      const filteredGeoJSON = {
        type: "FeatureCollection",
        features: filteredFeatures,
      };

      // Calculate the initial bounding box, which will be used to fit the map when no ecosystems are selected
      const initialBoundingBox = bbox(filteredGeoJSON);

      // add all ecosystem layers to the map
      for (let i in geojsonContent) {
        this.map.addSource(i, { type: "geojson", data: geojsonContent[i] });
      }
      // add all municipality, county, town bbox layers
      for (let i in geojsonEcosystemLayers) {
        const currentEcosystem = geojsonEcosystemLayers[i];
        const currentSubEcosystems =
          geojsonSubEcosystemLayers[currentEcosystem] || [];
        const currentSubEcosystemNames =
          subEcosystemNames[currentEcosystem] || [];

        for (let j in geojsonDetailLayers) {
          const currentLayer = geojsonDetailLayers[j];
          const currentSource = currentEcosystem + "-" + currentLayer + "-bbox";

          let layerDrawnObj = {
            id: `${currentEcosystem}-detail-${currentLayer}-bbox`,
            source: `${currentSource}`,
          };
          let layerDrawnStyle = {
            type: "fill",
            layout: {},
            paint: {
              "fill-opacity": 0,
            },
          };

          for (const key in layerDrawnStyle) {
            layerDrawnObj[key] = layerDrawnStyle[key];
          }
          this.map.addLayer(layerDrawnObj, "admin-0-boundary-bg");
        }

        for (let j in currentSubEcosystems) {
          const currentSource = currentEcosystem + "-watersheds-bbox";
          const currentSubEcosystem = currentSubEcosystems[j];
          const currentSubEcosystemName = currentSubEcosystemNames[j];

          let layerDrawnObj = {
            id: `${currentEcosystem}-subecosystem-${currentSubEcosystem}-bbox`,
            source: `${currentSource}`,
          };
          let layerDrawnStyle = {
            type: "fill",
            layout: {},
            paint: {
              "fill-opacity": 0,
            },
            filter: ["==", "name", currentSubEcosystemName],
          };

          for (const key in layerDrawnStyle) {
            layerDrawnObj[key] = layerDrawnStyle[key];
          }
          this.map.addLayer(layerDrawnObj, "admin-0-boundary-bg");
        }
      }

      this.mapLayers = this.map.getStyle().layers;

      // turn on opacity for activated ecosystems for map
      for (let i in this.props.ecosystemsForMap) {
        for (let j in this.mapLayers) {
          if (
            this.mapLayers[j].id.includes(this.props.ecosystemsForMap[i]) &&
            this.mapLayers[j].id.includes("ecosystem") &&
            !this.mapLayers[j].id.includes("sub")
          ) {
            this.map.setPaintProperty(
              this.mapLayers[j].id,
              "fill-opacity",
              0.5
            );
          }
        }
      }

      // get map bounds, fit to bounds, and add ecosystem layers to ecosystemLayers array
      let ecosystemObjects = [];
      let subEcosystemObjects = [];
      let detailObjects = [];

      // add subEcosystem layers to subEcosystemLayers array
      for (const layer of this.mapLayers) {
        for (const ecosystemForMap of this.props.ecosystemsForMap) {
          if (
            layer.id.includes(ecosystemForMap) &&
            layer.id.includes("ecosystem") &&
            !layer.id.includes("sub")
          ) {
            let obj = {
              id: layer.id,
              source: layer.source,
            };
            let ecosystemObj = { ...obj, name: layer.filter[2][0] };

            for (let i in geojsonContent[ecosystemSource].features) {
              if (
                geojsonContent[ecosystemSource].features[i].properties.NAME ===
                ecosystemObj.name
              ) {
                if (!ecosystemObj.coordinates) {
                  ecosystemObj.coordinates =
                    geojsonContent[ecosystemSource].features[
                      i
                    ].geometry.coordinates[0];
                }
              }
            }
            ecosystemObjects.push(ecosystemObj);
          } else if (
            layer.id.includes(ecosystemForMap) &&
            layer.id.includes("ecosystem") &&
            layer.id.includes("sub") &&
            layer.id.includes("bbox")
          ) {
            let obj = {
              id: layer.id,
              source: layer.source,
              ecosystem: ecosystemForMap + "-subecosystem",
            };
            let subEcosystemObj = { ...obj, name: layer.filter[2] };

            for (let geojsonLayer in geojsonContent) {
              if (layer.source === geojsonLayer) {
                for (let feature in geojsonContent[geojsonLayer].features) {
                  if (
                    geojsonContent[geojsonLayer].features[feature].properties
                      .name === subEcosystemObj.name
                  ) {
                    if (!subEcosystemObj.coordinates) {
                      subEcosystemObj.coordinates =
                        geojsonContent[geojsonLayer].features[
                          feature
                        ].geometry.coordinates;
                    }
                  }
                }
              }
            }
            subEcosystemObjects.push(subEcosystemObj);
          } else if (
            layer.id.includes(ecosystemForMap) &&
            layer.id.includes("detail")
          ) {
            let obj = {
              id: layer.id,
              source: layer.source,
            };
            detailObjects.push(obj);
          }
        }
      }

      // store all ecosystem, subEcosystem, and detail layers in state
      this.setState({
        ecosystemLayers: ecosystemObjects,
        subEcosystemLayers: subEcosystemObjects,
        detailLayers: detailObjects,
        mapBounds: initialBoundingBox,
        storedGeoJSONFiles: geojsonContent,
        style: this.map.getStyle(),
        finishedDownloadingJson: true,
        layersDrawn: true,
      });

      // now that ecosystems have been added, add a hover handler
      this.initialHoverHandler();

      // Map has finished rendering all layers
      this.props.disableInitializing();
    });

    this.map.on("click", (e) => {
      this.clickHandler(e);
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.activeEcosystem !== this.props.activeEcosystem ||
      prevProps.activeSubEcosystem !== this.props.activeSubEcosystem
    ) {
      this.setState({
        prevActiveEcosystem: prevProps.activeEcosystem,
        prevActiveSubEcosystem: prevProps.activeSubEcosystem,
      });
    }

    // if an active story has been selected (clicked)...
    if (
      this.props.activeStory &&
      this.props.activeStory !== prevProps.activeStory
    ) {
      const detailType =
        this.props.activeStory["Type of Municipality"] === "tribe" ||
        this.props.activeStory["Type of Municipality"] === "city"
          ? "village"
          : this.props.activeStory["Type of Municipality"];
      const activeStoryName = this.props.activeStory["Name of Municipality"];

      for (const detailFeature of this.state.detailFeatures) {
        if (
          activeStoryName.includes(detailFeature.name) &&
          detailType === detailFeature.type
        ) {
          this.map.fitBounds(detailFeature.geometry, {
            maxZoom: 11,
            padding: 50,
            duration: 5000,
            offset: [0, 0],
          });
          break;
        }
      }
    }

    // if an ecosystem has been selected...
    if (
      this.props.activeEcosystem &&
      this.props.activeEcosystem !== prevProps.activeEcosystem
    ) {
      if (!this.props.itemsDisabled) {
        this.props.disableItems();
      }
      for (let i in this.state.ecosystemLayers) {
        if (
          this.state.ecosystemLayers[i].id.includes(this.props.activeEcosystem)
        ) {
          let mapBounds = this.state.ecosystemLayers[i].coordinates;
          mapBounds = mapBounds.reduce(
            function (bounds, coord) {
              return bounds.extend(coord);
            },
            new mapboxgl.LngLatBounds(mapBounds[0], mapBounds[0])
          );
          // pan camera to fitBounds of the selected ecosystem
          this.fitBounds(mapBounds);
          this.setState({
            ecosystemBounds: mapBounds,
          });
          this.map.once("moveend", () => {
            this.updateDetailFeatures();
            this.detailHoverHandler();
          });
        } else {
          this.props.resetActiveStory();
          for (let layer of this.mapLayers) {
            if (
              layer.id.includes("detail") &&
              !layer.id.includes("bbox") &&
              layer.id.includes(this.props.activeEcosystem)
            ) {
              // Turn contextual layers on for active ecosystem
              if (layer.type === "line") {
                this.map.setPaintProperty(layer.id, "line-opacity", 0.75);
              } else if (layer.type === "fill") {
                this.map.setPaintProperty(layer.id, "fill-opacity", 0.75);
              } else if (layer.type === "symbol") {
                this.map.setPaintProperty(layer.id, "text-opacity", 1);
              }
            } else if (
              (layer.id.includes("detail") || layer.id.includes("sub")) &&
              !layer.id.includes(this.props.activeEcosystem)
            ) {
              // Turn contextual layers off for non-active ecosystems
              if (layer.type === "line") {
                this.map.setPaintProperty(layer.id, "line-opacity", 0);
              } else if (layer.type === "fill") {
                this.map.setPaintProperty(layer.id, "fill-opacity", 0);
              } else if (layer.type === "symbol") {
                this.map.setPaintProperty(layer.id, "text-opacity", 0);
              }
            }
            if (
              layer.id.includes("ecosystem") &&
              !layer.id.includes(this.props.activeEcosystem) &&
              !layer.id.includes("sub")
            ) {
              // Make other ecosystem layers gray
              this.map.setPaintProperty(layer.id, "fill-color", "#808080");
              this.map.setPaintProperty(layer.id, "fill-opacity", 0.4);
            } else if (
              layer.id.includes("ecosystem") &&
              layer.id.includes(this.props.activeEcosystem) &&
              !layer.id.includes("sub")
            ) {
              // Restore original color for active ecosystem layer if turned gray before
              const originalColor = this.state.style.layers.find(
                (l) => l.id === layer.id
              )?.paint["fill-color"];
              const originalOpacity = this.state.style.layers.find(
                (l) => l.id === layer.id
              )?.paint["fill-opacity"];
              if (
                originalColor !== undefined &&
                originalOpacity !== undefined
              ) {
                this.map.setPaintProperty(
                  layer.id,
                  "fill-color",
                  originalColor
                );
                this.map.setPaintProperty(
                  layer.id,
                  "fill-opacity",
                  originalOpacity
                );
              }
            }
          }
        }
      }
    }
    // otherwise, if no ecosystems selected, reset style, and camera to fitBounds of all data
    else if (!this.props.activeEcosystem && prevProps.activeEcosystem) {
      this.fitBounds(this.state.mapBounds);

      for (let i in this.mapLayers) {
        const layer = this.mapLayers[i];

        if (layer.id.includes("ecosystem") || layer.id.includes("detail")) {
          switch (layer.type) {
            case "fill":
              const fillOriginalColor =
                this.state.style.layers[i].paint["fill-color"];
              const fillOriginalOpacity =
                this.state.style.layers[i].paint["fill-opacity"];
              this.map.setPaintProperty(
                layer.id,
                "fill-color",
                fillOriginalColor
              );
              this.map.setPaintProperty(
                layer.id,
                "fill-opacity",
                fillOriginalOpacity
              );
              break;
            case "line":
              const lineOriginalColor =
                this.state.style.layers[i].paint["line-color"];
              const lineOriginalOpacity =
                this.state.style.layers[i].paint["line-opacity"];
              this.map.setPaintProperty(
                layer.id,
                "line-color",
                lineOriginalColor
              );
              this.map.setPaintProperty(
                layer.id,
                "line-opacity",
                lineOriginalOpacity
              );
              break;
            case "symbol":
              const symbolOriginalOpacity =
                this.state.style.layers[i].paint["text-opacity"];
              this.map.setPaintProperty(
                layer.id,
                "text-opacity",
                symbolOriginalOpacity
              );
              break;
            default:
              break;
          }
        }
      }

      this.removeHoverHandler();
    }

    // If a subEcosystem has been selected...
    if (
      this.props.activeSubEcosystem &&
      this.props.activeSubEcosystem !== prevProps.activeSubEcosystem
    ) {
      this.state.subEcosystemLayers.forEach((subEcosystemLayer) => {
        if (
          subEcosystemLayer.id &&
          subEcosystemLayer.id.includes("-" + this.props.activeSubEcosystem)
        ) {
          let mapBounds = this.flattenArray(subEcosystemLayer.coordinates);
          let bounds = mapBounds.reduce(
            function (bounds, coord) {
              return bounds.extend(coord);
            },
            new mapboxgl.LngLatBounds(mapBounds[0], mapBounds[0])
          );
          // Pan camera to fitBounds of the selected subEcosystem
          this.fitBounds(bounds);

          // Show active subEcosystem line outline and label
          this.mapLayers.forEach((layer) => {
            if (
              layer.id === subEcosystemLayer.ecosystem ||
              layer.id === subEcosystemLayer.ecosystem + "-label"
            ) {
              // filter the layer to only show name that matches name field
              this.map.setFilter(layer.id, [
                "in",
                "name",
                subEcosystemLayer.name,
              ]);
              if (layer.type === "line") {
                this.map.setPaintProperty(layer.id, "line-opacity", 0.75);
              } else if (layer.type === "symbol") {
                this.map.setPaintProperty(layer.id, "text-opacity", 1);
              }
            } else if (
              layer.id.includes("rivers") &&
              layer.filter[2][0] === this.props.activeEcosystem + "-ecosystem"
            ) {
              // filter the layer to only show name that matches name field
              this.map.setFilter(layer.id, [
                "in",
                "OWNAME",
                subEcosystemLayer.name,
              ]);
              if (layer.type === "line") {
                this.map.setPaintProperty(layer.id, "line-opacity", 0.75);
                this.map.setPaintProperty(layer.id, "line-width", 4);
              }
            }
          });
        }
      });
    }
    // otherwise, if no subEcosystems selected, reset style, and camera to fitBounds of activeEcosystem
    else if (
      !this.props.activeSubEcosystem &&
      prevProps.activeSubEcosystem &&
      this.props.activeEcosystem === prevProps.activeEcosystem
    ) {
      this.fitBounds(this.state.ecosystemBounds);

      this.mapLayers.forEach((layer) => {
        if (layer.id.includes("ecosystem") || layer.id.includes("sub")) {
          if (layer.type === "line") {
            this.map.setPaintProperty(layer.id, "line-opacity", 0);
          } else if (layer.type === "symbol") {
            this.map.setPaintProperty(layer.id, "text-opacity", 0);
          }
        } else if (
          layer.id.includes("rivers") &&
          layer.filter[2][0] === this.props.activeEcosystem + "-ecosystem"
        ) {
          this.map.setFilter(layer.id, [
            "in",
            "layer",
            this.props.activeEcosystem + "-ecosystem",
          ]);
          if (layer.type === "line") {
            this.map.setPaintProperty(layer.id, "line-opacity", 0.75);
            this.map.setPaintProperty(layer.id, "line-width", 1.5);
          }
        }
      });
    }

    const detailLayer = this.state.detailLayers.find(
      (layer) =>
        layer.id.includes("municipalities") &&
        layer.id.includes(this.props.activeEcosystem)
    );
    const hoverLayer = detailLayer ? detailLayer.id : null;

    if (this.state.detailHover) {
      // refactor to focus only on detail layers from active ecosystem
      this.map.on("mouseenter", hoverLayer, () => {
        this.hoverPointer();
      });
      this.map.on("mouseleave", hoverLayer, () => {
        this.noPointer();
      });
      this.map.on("mouseleave", hoverLayer, this.noPointer());
    } else {
      this.map.on("mouseenter", hoverLayer, () => {
        this.noPointer();
      });
    }

    this.map.on("idle", () => {
      if (this.props.activeEcosystem && this.props.itemsDisabled) {
        this.props.enableItems();
      }
    });
  }

  // to handle arrays within arrays for coordinates; used for subEcosystems
  flattenArray(arr) {
    let flattened = [];

    for (let item of arr) {
      if (Array.isArray(item)) {
        if (item.some((subitem) => Array.isArray(subitem))) {
          flattened.push(...this.flattenArray(item));
        } else {
          flattened.push(item);
        }
      } else {
        flattened.push(item);
      }
    }

    return flattened;
  }

  // for click and hover events based on BBOX geojson files
  updateDetailFeatures() {
    this.setState({
      detailFeatures: [],
    });

    let geoJSONKeys = Object.keys(this.state.storedGeoJSONFiles);
    let geoJSONValues = Object.values(this.state.storedGeoJSONFiles);
    let detailFeatures = [];
    let mapSourceLayer = [];

    for (let i in geoJSONKeys) {
      if (
        geoJSONKeys[i].includes(this.props.activeEcosystem) &&
        (geoJSONKeys[i].includes("municipalities") ||
          geoJSONKeys[i].includes("counties") ||
          geoJSONKeys[i].includes("towns"))
      ) {
        let detailObj = [geoJSONKeys[i], geoJSONValues[i].features];
        mapSourceLayer.push(detailObj);
      }
    }

    mapSourceLayer.forEach(([sourceLayer, features]) => {
      features.forEach((feature) => {
        const detailObj = {
          name: feature.properties.NAME,
          source: sourceLayer,
          layer: feature.properties.layer,
        };

        if (this.state.detailFeatures.some((e) => e.name === detailObj.name)) {
          return;
        }

        let detailType = "";
        if (sourceLayer.includes("counties")) {
          detailType = "county";
        } else if (sourceLayer.includes("municipalities")) {
          detailType = "village";
        } else if (sourceLayer.includes("towns")) {
          detailType = "town";
        }

        detailObj.type = detailType;

        let bounds = feature.geometry.coordinates;
        bounds = this.flattenArray(bounds);

        if (bounds !== null) {
          bounds = bounds.reduce(
            (bounds, coord) => bounds.extend(coord),
            new mapboxgl.LngLatBounds(bounds[0], bounds[0])
          );
          detailObj.geometry = bounds;
        }

        detailFeatures.push(detailObj);
      });
    });

    this.setState({
      detailFeatures: detailFeatures,
    });
  }

  // activate click events on ecosystem and detail layers
  clickHandler(e) {
    const bbox = [
      [e.point.x - 5, e.point.y - 5],
      [e.point.x + 5, e.point.y + 5],
    ];

    for (const f of this.state.ecosystemLayers) {
      const selectedFeatures = this.map.queryRenderedFeatures(bbox, {
        layers: [f.id],
      });

      if (selectedFeatures.length !== 0) {
        const clickedPlace = selectedFeatures[0].properties.NAME.replace(
          /[ ,.]/g,
          ""
        ).toLowerCase();

        if (
          !f.id.includes(this.props.activeEcosystem) &&
          !f.id.includes("sub")
        ) {
          // Handle clicking on a different ecosystem layer
          let ecosystem = "";
          if (selectedFeatures[0].properties.NAME === "St. Lawrence") {
            ecosystem = "St. Lawrence River Ecosystem";
          } else if (selectedFeatures[0].properties.NAME === "Cape Cod") {
            ecosystem = "Cape Cod Bay Ecosystem";
          } else if (selectedFeatures[0].properties.NAME === "Wolf") {
            ecosystem = "Wolf River Ecosystem";
          } else if (selectedFeatures[0].properties.NAME === "Rappahannock") {
            ecosystem = "Rappahanock River Ecosystem";
          }
          this.props.onMapFeatureClick(ecosystem);
          return;
        } else if (
          f.id.includes(this.props.activeEcosystem) &&
          this.props.activeEcosystem === clickedPlace
        ) {
          // Handle clicking on the active ecosystem layer
          for (const g of this.state.detailLayers) {
            const selectedDetailFeatures = this.map.queryRenderedFeatures(
              bbox,
              {
                layers: [g.id],
              }
            );

            if (
              selectedDetailFeatures.length !== 0 &&
              selectedDetailFeatures[0].layer.id.includes("municipalities")
            ) {
              // Handle clicking on a detail layer within the active ecosystem
              this.props.onDetailClick(selectedDetailFeatures);
              return;
            }
          }
          return;
        }
      }
    }
  }

  // initial state hover (no ecosystem selecteD)
  initialHoverHandler() {
    for (let f of this.state.ecosystemLayers) {
      if (!f.id.includes(this.props.activeEcosystem) && !f.id.includes("sub")) {
        let ecosystem = f.id;
        this.map.on("mouseenter", ecosystem, () => {
          // hover behavior for polygons
          if (
            !ecosystem.includes(this.props.activeEcosystem) &&
            !ecosystem.includes("sub")
          ) {
            this.map.getCanvas().style.cursor = "pointer";
            this.map.setPaintProperty(
              ecosystem,
              "fill-outline-color",
              "rgba(255, 255, 255, 1)"
            );
            this.map.setPaintProperty(ecosystem, "fill-opacity", 0.75);
          }
        });
        this.map.on("mouseleave", ecosystem, () => {
          // hover behavior for polygons
          this.map.getCanvas().style.cursor = "";
          this.map.setPaintProperty(
            ecosystem,
            "fill-outline-color",
            "rgba(255, 255, 255, 0)"
          );
          this.map.setPaintProperty(ecosystem, "fill-opacity", 0.5);
        });
      }
    }
  }

  // turn on detail hover state
  detailHoverHandler() {
    this.setState({ detailHover: true });
  }

  // turn off detail hover state
  removeHoverHandler() {
    this.setState({ detailHover: false });
  }

  // general fitBounds function used across the component
  fitBounds(mapBounds) {
    try {
      this.map.fitBounds(mapBounds, {
        padding: 100,
        duration: 3500,
        offset: [0, 0],
      });
    } catch (error) {
      console.error(error);
    }
  }

  hoverPointer() {
    this.map.getCanvas().style.cursor = "pointer";
  }

  noPointer() {
    this.map.getCanvas().style.cursor = "";
  }

  render() {
    return <div ref={this.mapContainer} className="mapContainer" />;
  }
}
